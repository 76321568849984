import { fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import styled from "styled-components/macro"
import { fontWeight, palette } from "../../../utils/style-utils"

export const TableWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  justify-content: flex-start;
  @media (max-width: 980px) {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
`
export const MultipleEntriesManagePlayerContent = styled.div`
  display: flex;
  flex-direction: column;
  background: ${palette.white};
  border: 1px solid ${palette.gray90};
  box-sizing: border-box;
  border-top: none;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  overflow: hidden;

  @media (max-width: 980px) {
    position: relative;
    width: 100%;
  }
`

export const MultipleEntriesManagePlayerHeader = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-top: none;
  padding: 0 1.5rem;

  @media (max-width: 980px) {
    padding: 0;
  }

  & > .player-header-title {
    font-family: ${fontFamily.base};
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0px;
    text-align: left;
    color: ${palette.gray20};
    padding: 1.25rem 0;
    text-transform: uppercase;

    @media (max-width: 980px) {
    padding: 1.25rem 1.5rem;
  }
  }

  & > .player-header-subtitle {
    font-family: ${fontFamily.base};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: -0.1px;
    text-align: left;
    color: ${palette.gray40};

    @media (max-width: 980px) {
      padding: 0 1.5rem;
    }
  }
  & > .player-header-actions {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    border-top: none;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;

    & > .player-header-actions-search {
      display: flex;
      box-sizing: border-box;
      @media (max-width: 980px) {
        padding: 0 1rem;
        margin-left: auto;
      }
    }
    & .action-buttons {
      display: flex;
      gap: 1rem;

      & > .email-pool-cta {
        margin: 0;
      }
    }

    @media (max-width: 980px) {
      flex-direction: column;
      gap: 1.5rem; 
      padding-bottom: 0.5rem;
      & > .action-buttons-container {
        border-top: 1px solid ${palette.gray90};
        border-bottom: 1px solid ${palette.gray90};
        padding: 0.75rem 0;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        width: 100%; 
        position: relative; 
        box-shadow: inset -20px 0 16px -16px rgba(0, 0, 0, 0.2);
      }

      }

      & > .action-buttons-container > .action-buttons {
        display: inline-flex; 
        gap: 1rem;
        white-space: nowrap;
        @media (max-width: 980px) {
          padding: 0 1rem;
        }
      }
    }
  }
`

export const MultipleEntriesPlayerRowWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border-top: 1px solid ${palette.gray90};
  background-color: ${palette.white};
  min-width: 100%;
  width: fit-content;
  position: relative;
  overflow: visible;
  padding: 0 1.5rem;
  height: 3rem;
  box-sizing: border-box;
  font-family: ${fontFamily.base};
  font-size: 14px;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: -0.1px;
  font-weight: ${fontWeight.regular};
  text-align: left;
  align-items: center;
  color: ${palette.gray20};

  & > .player-name {
    flex: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    padding-right: 1.5rem;
    min-width: 150px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 980px) {
      position: sticky;
      left: 1.5rem;
      background: ${palette.white};
      z-index: 1;
      margin-right: 1.5rem;
      padding-right: 0.5rem;
      box-shadow: inset -1.5rem 0 0 ${palette.white}, -1.5rem 0 0 ${palette.white}, 16px 0 30px -14px rgba(0, 0, 0, 0.2);

      & > .name {
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    & > .player-name-manager-icon {
      margin-left: 0.25rem;
      color: ${palette.yellow};
    }
  }
  & > .player-email {
    font-weight: ${fontWeight.bold};
    flex: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 1.5rem;
  }
  & > .total-brackets {
    flex: 1.5;
    padding-right: 1rem;
  }
  & > .brackets-with-picks {
    flex: 2;
    padding-right: 1rem;
  }
  & > .actions-on-player {
    font-weight: 400;
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 2.5rem;
    & > .player-is-manager {
      width: 4rem;
      text-align: right;
    }
  }

  &.is-my-entry:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0.25rem;
    background-color: ${palette.lightBlue3};
    border-radius: 0 0.25rem 0.25rem 0;
  }
`

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  color: ${palette.gray20};
  background: ${palette.white};
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;

  & > .section-title {
    font-family: ${fontFamily.base};
    font-size: 1rem;
    font-style: normal;
    font-weight: ${fontWeight.bold};
    line-height: 1;
    letter-spacing: -0.1px;
    text-align: left;
  }
  & > .pagination-info {
    font-family: ${fontFamily.base};
    font-style: normal;
    font-weight: ${fontWeight.regular};
    letter-spacing: -0.1px;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
  }
`

export const MultipleEntriesManagePlayerStickyTable = styled.div`
  flex: none;
  min-width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 980px) {
    & > .scrollable-content {
      overflow-x: auto;
      min-width: 100%;
    }
  }

  & > .multiple-entries-players-table-header {
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;

    & > span {
      font-family: ${fontFamily.condensed};
      font-size: 0.75rem;
      font-style: normal;
      font-weight: ${fontWeight.medium};
      line-height: 1;
      letter-spacing: 1.25px;
      text-align: left;
      color: ${palette.gray50};
      text-transform: uppercase;
    }
    & > .player-name {
      flex: 2;
      padding-right: 1.5rem;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      @media (max-width: 980px) {
        position: sticky;
        left: 1.5rem;
        background: ${palette.white};
        z-index: 1;
        margin-right: 1.5rem;
        padding-right: 0.5rem;
        box-shadow: inset -1.5rem 0 0 ${palette.white}, -1.5rem 0 0 ${palette.white};

        &::before {
          content: "";
          position: absolute;
          top: -1rem;
          bottom: -0.5rem;
          right: 0;
          width: 4px;
          pointer-events: none;
          box-shadow: inset -1.5rem 0 0 ${palette.white}, -1.5rem 0 0 ${palette.white}, 4px 0 7px -1px rgba(0, 0, 0, 0.2);
        }
      }
    }
    & > .player-email {
      flex: 3;
      padding-right: 1.5rem;
    }
    & > .total-brackets {
      flex: 1.5;
      padding-right: 1rem;
      letter-spacing: 0.8px;
    }
    & > .brackets-with-picks {
      flex: 2;
      padding-right: 1rem;
      letter-spacing: 0.8px;
    }
    & > .table-header-actions {
      flex: 0;
      min-width: 2.5rem;
      & > .player-is-manager {
        width: 4rem;
        text-align: right;
        display: block;
      }
    }
  }
  @media (max-width: 980px) {
    & > .multiple-entries-players-table-header,
    ${MultipleEntriesPlayerRowWrap} {
      min-width: 800px;
      width: max-content;
    }
  }
`
